<template>
  <LazyClientOnly>
    <Popper
      v-if="popperInited"
      arrow
      :placement="placement"
      :z-index="20"
      class="!flex cursor-pointer"
      @open:popper="$emit('open')"
      @close:popper="$emit('close')"
    >
      <slot />

      <template #content="{ close }">
        <div
          data-tid="userMenu"
          v-bind="$attrs"
          class="max-w-full divide-y divide-gray-100 rounded-lg bg-white dark:divide-gray-600 dark:bg-gray-700"
          :class="[
            {
              'w-auto': size === 'md',
              'w-80': size === 'lg',
              'w-96': size === 'xl',
            },
          ]"
        >
          <template v-if="$slots.header">
            <div class="px-4 py-3" data-tid="userMenuHeader">
              <slot name="header" />
            </div>
          </template>

          <template v-if="$slots.content">
            <BaseDropdownItemWrapper>
              <slot
                name="content"
                :close="close"
              />
            </BaseDropdownItemWrapper>
          </template>
          <template v-if="$slots.custom">
            <slot
              name="custom"
              :close="close"
            />
          </template>
          <template v-if="$slots.footer">
            <BaseDropdownItemWrapper>
              <slot
                name="footer"
                :close="close"
              />
            </BaseDropdownItemWrapper>
          </template>
        </div>
      </template>
    </Popper>
    <span
      v-else
      @mouseover="initPopper"
    >
      <slot />
    </span>
  </LazyClientOnly>
</template>

<script lang="ts" setup>
import Popper from 'vue3-popper'

export type DropdownPlacement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end'

type DropdownSize = 'md' | 'lg' | 'xl'

defineEmits<{
  (e: 'open'): void
  (e: 'close'): void
}>()

interface Props {
  placement?: DropdownPlacement
  size?: DropdownSize
}

withDefaults(defineProps<Props>(), {
  placement: 'bottom',
  size: 'md',
})

const popperInited = ref(false)

const initPopper = () => {
  if (!popperInited.value) {
    popperInited.value = true
  }
}
</script>

<style scoped>
:deep(.popper #arrow) {
  z-index: -1;
}

:deep(.popper #arrow::before) {
  z-index: 1;
}
</style>
